define("discourse/plugins/discourse-anonymous-categories/discourse/pre-initializers/extend-category-for-anonymous", ["exports", "@ember/object", "discourse/models/category"], function (_exports, _object, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'extend-category-for-anonymous',
    before: 'inject-discourse-objects',
    initialize() {
      _category.default.reopen({
        force_anonymous_posting: (0, _object.computed)("custom_fields.force_anonymous_posting", {
          get() {
            return this?.custom_fields?.force_anonymous_posting === "true";
          }
        })
      });
    }
  };
});