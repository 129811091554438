define("discourse/plugins/discourse-anonymous-categories/discourse/connectors/category-custom-settings/anonymous-categories", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    onChangeSetting(value) {
      this.set("category.custom_fields.force_anonymous_posting", value ? "true" : "false");
    }
  }, [["method", "onChangeSetting", [_object.action]]]);
});